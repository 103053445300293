import React from 'react';

const Error500 = () => {
  return (
    <div style={{ margin: 'auto', height: '100vh', display: 'flex' }}>
      <div style={{ margin: 'auto' }} className="col justify-content-center align-items-center">
        <img
          style={{ display: 'block', margin: 'auto', maxHeight: '30vh', maxWidth: '60vw' }}
          alt="logo"
          src={require('../../../assets/img/logo/error500.png')} />
        <div className="text-center">
          <h3 className="text-center mb-5">Hubo un problema. Por favor intente nuevamente más tarde.</h3>
          <button onClick={() => window.location.replace(window.location.origin)} className="btn btn-primary">Go back</button>
        </div>
      </div>
    </div>
  );
};

export default Error500;
