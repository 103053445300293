/* DEPENDENCIES */
import React, { useEffect, useState, lazy } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import OneSignal from 'react-onesignal';
/* STORE */
import Store from './reducers';
/* COMPONENTS */
import "react-table-6/react-table.css";
import "./components/@vuexy/rippleButton/RippleButton";
import "react-perfect-scrollbar/dist/css/styles.css";
import "prismjs/themes/prism-tomorrow.css";
import checkRole from './utils/checkRole';
import Loader from "./components/assets/spinner/Fullscreen-spinner";
import AppContainer from './components/container/AppContainer';
import OrganizationChart from './screens/OrgChart';
const Feed = lazy(() => import('./screens/Feed'));
const FeedDetails = lazy(() => import('./screens/Feed.Details'));
const Commercial = lazy(() => import('./screens/Commercial'));
const CommercialList = lazy(() => import('./screens/Commercial.List'));
const CommercialEdit = lazy(() => import('./screens/Commercial.Edit'));
const Contents = lazy(() => import('./screens/Contents'));
const ContentsEdit = lazy(() => import('./screens/Contents.Edit'));
const ContentsViews = lazy(() => import('./screens/Contents.Views'));
const ContentsPendingViews = lazy(() => import('./screens/Contents.PendingViews'));
const ContentsPreview = lazy(() => import('./screens/Contents.Preview'));
const ProfilesList = lazy(() => import('./screens/Profiles.List'));
const ProfilesEdit = lazy(() => import('./screens/Profiles.Edit'));
const ShortcutsList = lazy(() => import('./screens/Shortcuts.List'));
const ShortcutsEdit = lazy(() => import('./screens/Shortcuts.Edit'));
const Error403 = lazy(() => import('./components/assets/errors/Error403'));
const Error404 = lazy(() => import('./components/assets/errors/Error404'));
const BranchesList = lazy(() => import('./screens/Branches.List'));
const BranchesEdit = lazy(() => import('./screens/Branches.Edit'));
const EmailsList = lazy(() => import('./screens/Emails.List'));
const Conversations = lazy(() => import('./screens/conversations/Conversations'));
const EventsList = lazy(() => import('./screens/Events.List'));
const EventsEdit = lazy(() => import('./screens/Events.Edit'));
const FilesList = lazy(() => import('./screens/Files.List'));
const Tutorials = lazy(() => import('./screens/Tutorials'));
const Notifications = lazy(() => import('./screens/Notifications'));
const FilesDetails = lazy(() => import('./screens/Files.Details'));
const NotificationsList = lazy(() => import('./screens/Notifications.List'));
const Roles = lazy(() => import('./screens/RolesList'));
const RolesEdit = lazy(() => import('./screens/Roles.Edit'));
const PasswordRequest = lazy(() => import('./screens/PasswordRequest'));
const PasswordReset = lazy(() => import('./screens/PasswordReset'));
const Login = lazy(() => import('./screens/Login'));
const Profile = lazy(() => import('./screens/Profile'));
const SettingsList = lazy(() => import('./screens/Settings.List'));
const SettingsEdit = lazy(() => import('./screens/Settings.Edit'));
const SettingsSchedule = lazy(() => import('./screens/Settings.Schedule'));
const SurveysSelect = lazy(() => import('./screens/Surveys.Select'));
const SurveysFill = lazy(() => import('./screens/Surveys.Fill'));
const SurveysResponsesList = lazy(() => import('./screens/SurveysResponses.List'));
const SurveysResponsesView = lazy(() => import('./screens/SurveysResponses.View'));
const SurveysList = lazy(() => import('./screens/Surveys.List'));
const SurveysEdit = lazy(() => import('./screens/Surveys.Edit'));
const UsersList = lazy(() => import('./screens/Users.List'));
const UsersEdit = lazy(() => import('./screens/Users.Edit'));

const App = () => {
  const [auth, setAuth] = useState(Store.getState().auth.isAuthenticated);
  const [redirect, setRedirect] = useState(window.location.pathname + window.location.search);
  const location = useLocation();

  useEffect(() => {
    setRedirect(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    const login = Store.subscribe(() => {
      setAuth(Store.getState().auth.isAuthenticated);
    });

    OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APP_ID, allowLocalhostAsSecureOrigin: true }).then(e => {
      console.log('OneSignal Initialized', e);
      OneSignal.Slidedown.promptPush();
    });

    return function cleanup() {
      login();
    };
  }, []);

  return (
    <Provider store={Store}>
      <Loader />
      {
        auth
          ?
          (
            <AppContainer>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/' element={<Feed />} />
                <Route path='organization' element={<OrganizationChart />} />
                <Route path='report/commercial' element={checkRole('commercial_reports_read') ? <Commercial /> : <Error403 title={'Reportes comerciales'} perm={'commercial_reports_read'} />} />
                <Route path='report/commercial/create' element={checkRole('commercial_reports_write') ? <CommercialEdit /> : <Error403 title={'Reportes comerciales'} perm={'commercial_reports_write'} />} />
                <Route path='report/commercial/edit' element={checkRole('commercial_reports_edit') ? <CommercialList /> : <Error403 title={'Reportes comerciales'} perm={'commercial_reports_edit'} />} />
                <Route path='report/commercial/edit/:id' element={checkRole('commercial_reports_edit') ? <CommercialEdit /> : <Error403 title={'Reportes comerciales'} perm={'commercial_reports_edit'} />} />
                <Route path='contents' element={checkRole('contents_read') ? <Contents /> : <Error403 title={'Contenidos'} perm={'contents_read'} />} />
                <Route path='contents/create' element={checkRole('contents_write') ? <ContentsEdit /> : <Error403 title={'Contenidos'} perm={'contents_write'} />} />
                <Route path='contents/edit/:id' element={checkRole('contents_write') ? <ContentsEdit /> : <Error403 title={'Contenidos'} perm={'contents_write'} />} />
                <Route path='contents/interactions/:id/view' element={checkRole('contents_read') ? <ContentsViews /> : <Error403 title={'Visualizaciones de Contenidos'} perm={'contents_read'} />} />
                <Route path='contents/interactions/:id/view/pending' element={checkRole('contents_write') ? < ContentsPendingViews /> : <Error403 title={'Visualizaciones de Contenidos'} perm={'contents_read'} />} />
                <Route path='contents/preview/:id' element={checkRole('contents_write') ? < ContentsPreview /> : <Error403 title={'Contenidos vista previa'} perm={'contents_write'} />} />
                <Route path='emails' element={checkRole('emails_read') ? <EmailsList /> : <Error403 title={'Emails'} perm={'emails_red'} />} />
                <Route path='notifications' element={<Notifications />} />
                <Route path='notifications/list' element={checkRole('notifications_read') ? <NotificationsList /> : <Error403 title={'Notifications'} perm={'notifications_read'} />} />
                <Route path='events' element={checkRole('events_read') ? <EventsList /> : <Error403 title={'Eventos'} perm={'events_read'} />} />
                <Route path='events/create' element={checkRole('events_write') ? <EventsEdit /> : <Error403 title={'Eventos'} perm={'events_write'} />} />
                <Route path='events/edit/:id' element={checkRole('events_write') ? <EventsEdit /> : <Error403 title={'Eventos'} perm={'events_write'} />} />
                <Route path='feed' element={<Feed />} />
                <Route path='feed/:id' element={<FeedDetails />} />
                <Route path='files' element={<FilesList />} />
                <Route path='files/:id' element={checkRole('files_read') ? <FilesList /> : <Error403 title={'Archivos'} perm={'files_read'} />} />
                <Route path='files/preview/:id' element={checkRole('files_read') ? <FilesDetails /> : <Error403 title={'Archivos'} perm={'files_read'} />} />
                <Route path='conversations' element={<Conversations />} />
                <Route path='conversations/:filter' element={<Conversations />} />
                <Route path='contents' element={checkRole('contents_read') ? <Contents /> : <Error403 title={'Contenidos'} perm={'contents_read'} />} />
                <Route path='contents/create' element={checkRole('contents_write') ? <ContentsEdit /> : <Error403 title={'Contenidos'} perm={'contents_write'} />} />
                <Route path='contents/edit/:id' element={checkRole('contents_write') ? <ContentsEdit /> : <Error403 title={'Contenidos'} perm={'contents_write'} />} />
                <Route path='shortcuts' element={checkRole('shortcuts_read') ? <ShortcutsList /> : <Error403 title={'Atajos'} perm={'shortcuts_read'} />} />
                <Route path='shortcuts/edit/:id' element={checkRole('shortcuts_write') ? <ShortcutsEdit /> : <Error403 title={'Atajos'} perm={'shortcuts_write'} />} />
                <Route path='shortcuts/create' element={checkRole('shortcuts_write') ? <ShortcutsEdit /> : <Error403 title={'Atajos'} perm={'shortcuts_write'} />} />
                <Route path='roles' element={checkRole('roles_read') ? <Roles /> : <Error403 title={'Roles'} perm={'roles_read'} />} />
                <Route path='roles/create' element={checkRole('roles_read') ? <RolesEdit /> : <Error403 title={'Roles'} perm={'roles_read'} />} />
                <Route path='roles/edit/:id' element={checkRole('roles_read') ? <RolesEdit /> : <Error403 title={'Roles'} perm={'roles_read'} />} />
                <Route path='branches' element={checkRole('branches_read') ? <BranchesList /> : <Error403 title={'Sucursales'} perm={'branches_read'} />} />
                <Route path='branches/create' element={checkRole('branches_read') ? <BranchesEdit /> : <Error403 title={'Sucursales'} perm={'branches_read'} />} />
                <Route path='branches/edit/:id' element={checkRole('branches_write') ? <BranchesEdit /> : <Error403 title={'Sucursales'} perm={'branches_write'} />} />
                <Route path='profiles' element={checkRole('users_read') ? <ProfilesList /> : <Error403 title={'Usuarios'} perm={'users_read'} />} />
                <Route path='profiles/edit/:id' element={checkRole('profiles_read') ? <ProfilesEdit /> : <Error403 title={'Perfiles'} perm={'profiles_read'} />} />
                <Route path='profiles/create' element={checkRole('profiles_write') ? <ProfilesEdit /> : <Error403 title={'Perfiles'} perm={'profiles_write'} />} />
                <Route path='users' element={checkRole('users_read') ? <UsersList /> : <Error403 title={'Usuarios'} perm={'users_read'} />} />
                <Route path='profiles' element={checkRole('users_read') ? <ProfilesList /> : <Error403 title={'Usuarios'} perm={'users_read'} />} />
                <Route path='profiles/edit/:id' element={checkRole('users_read') ? <ProfilesEdit /> : <Error403 title={'Usuarios'} perm={'users_read'} />} />
                <Route path='profiles/create' element={checkRole('users_read') ? <ProfilesEdit /> : <Error403 title={'Usuarios'} perm={'users_read'} />} />
                <Route path='users/create' element={checkRole('users_read') ? <UsersEdit /> : <Error403 title={'Usuarios'} perm={'users_write'} />} />
                <Route path='users/edit/:id' element={checkRole('users_read') ? <UsersEdit /> : <Error403 title={'Usuarios'} perm={'users_write'} />} />
                <Route path='settings' element={checkRole('settings_read') ? <SettingsList /> : <Error403 title={'Configuración'} perm={'settings_read'} />} />
                <Route path='settings/edit/:key' element={checkRole('settings_write') ? <SettingsEdit /> : <Error403 title={'Configuración'} perm={'settings_write'} />} />
                <Route path='settings/scheduled' element={checkRole('settings_read') ? <SettingsSchedule /> : <Error403 title={'Configuración'} perm={'settings_read'} />} />
                <Route path='settings/:key/schedule' element={checkRole('settings_read') ? <SettingsEdit /> : <Error403 title={'Configuración'} perm={'settings_read'} />} />
                <Route path='surveys/fill' element={checkRole('surveys_write') ? <SurveysSelect /> : <Error403 title={'Auditorías'} perm={'surveys_write'} />} />
                <Route path='surveys/fill/:id' element={checkRole('surveys_write') ? <SurveysFill /> : <Error403 title={'Auditorías'} perm={'surveys_write'} />} />
                <Route path='surveys/completed' element={checkRole('surveys_read') ? <SurveysResponsesList /> : <Error403 title={'Auditorías'} perm={'surveys_read'} />} />
                <Route path='surveys/completed/:id' element={<SurveysResponsesView />} />
                <Route path='surveys/manage' element={checkRole('surveys_read') ? <SurveysList /> : <Error403 title={'Auditorías'} perm={'surveys_read'} />} />
                <Route path='surveys/manage/create' element={checkRole('surveys_write') ? <SurveysEdit /> : <Error403 title={'Auditorías'} perm={'surveys_write'} />} />
                <Route path='surveys/manage/edit/:id' element={checkRole('surveys_write') ? <SurveysEdit /> : <Error403 title={'Auditorías'} perm={'surveys_write'} />} />
                <Route path='tutorials' element={<Tutorials />} />
                <Route path='profile' element={<Profile />} />
                <Route path='*' element={<Error404 />} />
              </Routes>
            </AppContainer>
          )
          :
          <Routes>
            <Route path='/login' element={<Login />} fullLayout />
            <Route path='/recover' element={<PasswordRequest />} />
            <Route path='/recover/:token' element={<PasswordReset />} />
            <Route path='/*' element={<Navigate to={`/login${(redirect && redirect.indexOf('login') === -1 && redirect !== '/') ? `?path=${redirect}` : ''}`} />} />
          </Routes>
      }
    </Provider >
  );
};

export default App;
