// stringToHslColor.js
export function stringToHslColor(str, saturation = 70, lightness = 95) {
    let hash = 0;
  
    for (let i = 0; i < str.length; i++) {
      // Simple hash computation
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    // Convert hash to a 0–360 range for hue
    const hue = Math.abs(hash) % 360;
  
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
  