import React, { useState, useEffect, useRef } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { getOrgChart, cancelOrgChart } from '../services/users';
import { stringToHslColor } from '../utils/colorFromString';
import Avatar from 'react-avatar';
import { ChevronRight } from "react-feather";

const OrgChart = () => {
    const [tree, setTree] = useState(null);
    // Reference to the scrollable container
    const containerRef = useRef(null);
    // This object will store the initial mouse and scroll positions when dragging starts
    const posRef = useRef({ left: 0, top: 0, x: 0, y: 0 });
    // Ref to track dragging state synchronously
    const draggingRef = useRef(false);
    // Optional state to toggle cursor style in the component
    const [isDragging, setIsDragging] = useState(false);

    const mouseDownHandler = (e) => {
        const container = containerRef.current;
        if (!container) return;

        // Set dragging flag using ref and update state for UI changes
        draggingRef.current = true;
        setIsDragging(true);
        posRef.current = {
            left: container.scrollLeft,
            top: container.scrollTop,
            x: e.clientX,
            y: e.clientY,
        };

        // Attach event listeners to the document for smooth dragging
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);

        // Prevent default behavior to avoid unwanted selections
        e.preventDefault();
    };

    const mouseMoveHandler = (e) => {
        // Use the ref value which updates synchronously
        if (!draggingRef.current) return;
        const container = containerRef.current;
        if (!container) return;

        // Calculate movement differences
        const dx = e.clientX - posRef.current.x;
        const dy = e.clientY - posRef.current.y;

        // Scroll the container in the opposite direction to the mouse movement
        container.scrollLeft = posRef.current.left - dx;
        container.scrollTop = posRef.current.top - dy;
    };

    const mouseUpHandler = () => {
        // End dragging
        draggingRef.current = false;
        setIsDragging(false);

        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
    };

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await getOrgChart();

                setTree(data.map(a => {
                    a.expanded = true;

                    a.children = a.children.map(b => {
                        b.expanded = true;
                        return b;
                    });

                    return a;
                }));
            } catch(err) {
                console.error(err);
            }
        };

        fetch();

        return () => {
            cancelOrgChart();
        };
    }, []);

    const nodeTemplate = (node) => {
        return (
            <div
                className="card white d-flex flex-column align-items-center justify-content-between mb-0 p-1 py-2"
                style={{
                    width: 250,
                    height: 200,
                    backgroundColor: stringToHslColor(node.department)
                }}>
                {node.profile_pic_thumbnail ? (
                    <img
                        src={node.profile_pic_thumbnail}
                        className="round"
                        height="40"
                        width="40"
                        alt="avatar"
                    />
                ) : (
                    <Avatar round={true} name={node.name} size="40" />
                )}
                <h5 className="mt-1"><b>{node.name}</b></h5>
                <p className="mb-0 text-dark" data-toggle="collapse" data-target={`#collapse-${node._id}`} aria-expanded="false" aria-controls={`collapse-${node._id}`}>
                    <b>{node.department}</b>
                    <ChevronRight size={15} color='#FF7075' />
                    <p className="collapse mb-0 text-dark" id={`collapse-${node._id}`}>{node.title}</p>
                </p>
            </div>
        );
    };

    return (
        <>
            {tree ? (
                <div
                    ref={containerRef}
                    className="card p-3"
                    onMouseDown={mouseDownHandler}
                    // Adjust dimensions and enable scrolling via CSS
                    style={{
                        width: '100%',
                        height: '80vh',
                        overflow: 'auto',
                        cursor: isDragging ? 'grabbing' : 'grab',
                        border: '1px solid #ccc',
                    }}
                >
                    <OrganizationChart value={tree} nodeTemplate={nodeTemplate} style={{ width: '100%' }} />
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default OrgChart;
