import axios from 'axios';
import Store from '../reducers';
const expired = 'TokenExpiredError';

export const CancelToken = axios.CancelToken;
export let cancelList;
export let cancelGetById;
export let cancelGetByName;
export let cancelOrgChart;
export let cancelCreate;
export let cancelEdit;
export let cancelGetBirthdays;
export let cancelAudience;
export let cancelDepartments;

export const exportXLSX = async (body) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/export`, body);
    return res.data;
  } catch(err) {
    if (err.response) {
      if (err.response && err.response.data.error === expired) {
        Store.dispatch({ type: 'LOGOUT' });
      }
      return err.response.data;
    }
    return err;
  }
};

export const list = async (options) => {
  const { sort, direction, filters, results, offset } = options;

  const body = {
    sort,
    direction,
    filters
  };

  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/getAll?results=${results}&offset=${offset}`,
      body,
      { cancelToken: new CancelToken(function executor(c) { cancelList = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  }
};

export const getById = async (id) => {
  Store.dispatch({ type: 'LOADING' });
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/get/${id}`,
      { cancelToken: new CancelToken(function executor(c) { cancelGetById = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  } finally {
    Store.dispatch({ type: 'LOADED' });
  }
};

export const getOrgChart = async (id) => {
  Store.dispatch({ type: 'LOADING' });
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/org-chart`,
      { cancelToken: new CancelToken(function executor(c) { cancelOrgChart = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  } finally {
    Store.dispatch({ type: 'LOADED' });
  }
};

export const create = async (body) => {
  Store.dispatch({ type: 'LOADING' });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/create`,
      body,
      { cancelToken: new CancelToken(function executor(c) { cancelCreate = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err.response;
  } finally {
    Store.dispatch({ type: 'LOADED' });
  }
};

export const edit = async (id, body) => {
  Store.dispatch({ type: 'LOADING' });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/edit/${id}`,
      body,
      { cancelToken: new CancelToken(function executor(c) { cancelEdit = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  } finally {
    Store.dispatch({ type: 'LOADED' });
  }
};

export const newPassword = async (body) => {
  Store.dispatch({ type: 'LOADING' });
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/password/change`,
      body,
      { cancelToken: new CancelToken(function executor(c) { cancelEdit = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response.data.error === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err.response.data;
  } finally {
    Store.dispatch({ type: 'LOADED' });
  }
};

export const setProfilePic = async (data) => {
  Store.dispatch({ type: 'LOADING' });
  let formData = new FormData();

  for (let k in data) {
    formData.append(k, data[k]);
  }
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/users/me/profile-image`, formData);

    Store.dispatch({ type: 'USER_IMAGE', image: res.data.image, thumbnail: res.data.thumbnail });

    return res.data;
  } catch(err) {
    if (err.response && err.response.data.error === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  } finally {
    Store.dispatch({ type: 'LOADED' });
  }
};

export const getDepartments = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/get-departments`,
      { cancelToken: new CancelToken(function executor(c) { cancelDepartments = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  };
};

export const getUsersByName = async (name) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/getByName?name=${name}`,
      { cancelToken: new CancelToken(function executor(c) { cancelGetByName = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  };
};

export const getBirthdays = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/getBirthdays`,
      { cancelToken: new CancelToken(function executor(c) { cancelGetBirthdays = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    throw err;
  };
};

export const getAudience = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/audience/calculate`, data,
      { cancelToken: new CancelToken(function executor(c) { cancelAudience = c; }) }
    );

    return res.data;
  } catch(err) {
    if (err.response && err.response?.data?.message?.name === expired) {
      Store.dispatch({ type: 'LOGOUT' });
    }
    return err;
  };
};
